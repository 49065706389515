<template>
  <!-- DROP DOWN MENU - Menu selector for data filtering -->
  <v-menu transition="slide-y-transition" :offset-y="true" :left="left" :right="right" bottom close-on-click>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" :color="color" depressed :outlined="outlined" elevation="0" class="pa-1 text-capitalize" :width="width" data-cy="change_semester_dropdown">
        <v-icon class="pr-2" color="accent">{{ icon }}</v-icon>
        {{ options[activeItem].label }}
        <v-spacer/>
        <v-icon v-if="chevron" color="#ADADAD" class="pl-2">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group data-cy="semester_options" v-model="activeItem">
        <template v-for="(option, idx) in options">
          <v-list-item :key="idx" active-class="accent--text font-weight-medium" @click="$emit('optionUpdate', idx)" :data-cy="option.label">
              <v-list-item-content>
                <v-list-item-title v-text="option.label"></v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DropMenu',
  props: {
    options: { type: Array, required: true },
    defaultActive: { type: Number, default: 0 },
    icon: { type: String, required: true },
    chevron: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    color: { type: String, default: 'transparent' },
    secondary: { type: Boolean, default: false },
    width: { type: String, default: '' },
    left: { type: Boolean, default: true },
    right: { type: Boolean, default: false }
  },
  data: () => ({
    activeItem: 0
  }),
  mounted () {
    if (this.defaultActive !== 0) {
      this.activeItem = this.defaultActive
      this.$emit('optionUpdate', this.activeItem)
    }
  },
  watch: {
    defaultActive: function (newValue) {
      this.activeItem = newValue
    }
  }
}
</script>

<style scoped>

</style>
